export enum EnvironmentEnum {
  NewCentury = 'newcentury',
  Aac = 'aac',
  ImpactfulHomeCare = 'impactfulhomecare',
  OpenArms = 'openarms',
  CarePoint = 'carepoint',
}

export enum DepartmentEnum {
  Philadelphia = 1,
  Allentown = 2,
  Golds = 2,
  Harrisburg = 3,
}
